@media screen and (max-width: 99999px) {
	._btn {
		composes: _btn from global;
	}
	.action {
		composes: action from global;
	}
	.action-outline {
		composes: action-outline from global;
	}
	.ghost {
		composes: ghost from global;
	}
}

@import './fonts.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	* {
		min-width: 0;
	}

	[hidden] {
		display: none !important;
	}

	:target:is(h1, h2, h3, h4, h5, h6) {
		scroll-margin-top: var(--header-height);
		animation: target 2s ease-in-out forwards;
	}

	@keyframes target {
		0%,
		50% {
			@apply bg-amber-400/50;
		}
	}

	html {
		accent-color: theme('colors.accent');
		caret-color: theme('colors.accent');
		scroll-behavior: smooth;
		max-width: 100vw; /* Ensure it never overflows */
	}

	label {
		cursor: pointer;
	}

	summary {
		cursor: pointer;
	}

	::-webkit-details-marker {
		display: none;
	}
	:root {
		--background: 0 0% 100%;
		--foreground: 0 0% 3.9%;
		--card: 0 0% 100%;
		--card-foreground: 0 0% 3.9%;
		--popover: 0 0% 100%;
		--popover-foreground: 0 0% 3.9%;
		--primary: 0 0% 9%;
		--primary-foreground: 0 0% 98%;
		--secondary: 0 0% 96.1%;
		--secondary-foreground: 0 0% 9%;
		--muted: 0 0% 96.1%;
		--muted-foreground: 0 0% 45.1%;
		--accent: 0 0% 96.1%;
		--accent-foreground: 0 0% 9%;
		--destructive: 0 84.2% 60.2%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 89.8%;
		--input: 0 0% 89.8%;
		--ring: 0 0% 3.9%;
		--chart-1: 12 76% 61%;
		--chart-2: 173 58% 39%;
		--chart-3: 197 37% 24%;
		--chart-4: 43 74% 66%;
		--chart-5: 27 87% 67%;
		--radius: 0.5rem;
	}
	.dark {
		--background: 0 0% 3.9%;
		--foreground: 0 0% 98%;
		--card: 0 0% 3.9%;
		--card-foreground: 0 0% 98%;
		--popover: 0 0% 3.9%;
		--popover-foreground: 0 0% 98%;
		--primary: 0 0% 98%;
		--primary-foreground: 0 0% 9%;
		--secondary: 0 0% 14.9%;
		--secondary-foreground: 0 0% 98%;
		--muted: 0 0% 14.9%;
		--muted-foreground: 0 0% 63.9%;
		--accent: 0 0% 14.9%;
		--accent-foreground: 0 0% 98%;
		--destructive: 0 62.8% 30.6%;
		--destructive-foreground: 0 0% 98%;
		--border: 0 0% 14.9%;
		--input: 0 0% 14.9%;
		--ring: 0 0% 83.1%;
		--chart-1: 220 70% 50%;
		--chart-2: 160 60% 45%;
		--chart-3: 30 80% 55%;
		--chart-4: 280 65% 60%;
		--chart-5: 340 75% 55%;
	}
}

@layer components {
	/* typography */

	._h {
		font-weight: bold;
	}

	.h1 {
		@apply _h text-5xl;
		line-height: 1.5;
	}

	.h2 {
		@apply _h text-2xl;
	}

	.h3 {
		@apply _h text-xl;
	}

	.h4 {
		@apply _h text-lg;
	}

	.h5 {
		@apply _h;
	}

	.h6 {
		@apply _h;
	}

	.technical {
		text-transform: uppercase;
		letter-spacing: 1px;
		font-weight: bold;
	}

	.text-shadow {
		text-shadow: 0 0 0.2em rgb(0 0 0 / 0.25);
	}

	.text-gradient {
		/* @apply bg-gradient-to-br from-accent/5 to-accent bg-clip-text text-transparent; */
	}

	/* ctas, links */

	._btn {
		@apply relative z-10 m-2 flex items-center justify-center border border-white bg-transparent px-6 py-4 text-sm text-white transition-all duration-200;
	}

	.bg-hover {
		@apply bg-white text-black transition-all duration-200;
		opacity: 0.85;
	}

	.action {
		@apply frosted-glass relative z-10 m-2 flex items-center justify-center rounded border border-white bg-transparent px-6 py-4 text-sm text-white shadow transition-all duration-200 hover:bg-white hover:text-black max-sm:w-full;
	}

	.action-outline {
		@apply frosted-glass relative z-10 m-2 flex items-center justify-center rounded border border-accent/10 bg-transparent px-6 py-4 text-sm text-accent transition-all duration-200 hover:border-accent/15;
		background: linear-gradient(
			to bottom right,
			var(--canvas-70),
			var(--canvas)
		);
	}

	.ghost {
		@apply frosted-glass relative z-10 m-2 flex items-center justify-center rounded border-transparent bg-transparent px-6 py-4 text-sm text-white transition-all duration-200 hover:bg-accent/5;
	}

	.link {
		@apply underline transition-all duration-200 hover:decoration-2;
	}

	.is-external::after {
		content: ' ↗';
		@apply align-top text-[0.7em];
	}

	/* layout */

	.section {
		@apply mx-auto max-w-screen-xl p-8 max-md:px-4 md:py-12;
	}

	/* richtext */

	.richtext {
		@apply space-y-2 leading-relaxed;

		& :is(h1, h2, h3, h4, h5, h6) strong {
			@apply text-gradient;
		}

		& h1 {
			@apply h1;
		}

		& h2 {
			@apply h2;
		}

		& h3 {
			@apply h3;
		}

		& h4 {
			@apply h4;
		}

		& h5 {
			@apply h5;
		}

		& h6 {
			@apply h6;
		}

		& :is(ul, ol) {
			list-style: revert;
			padding-left: 1.5em;
		}

		& a:not(:is(.action, .action-outline, .ghost)) {
			@apply link;

			&[href^='http'] {
				@apply is-external;
			}
		}

		& :not(pre) > code {
			@apply rounded bg-ink/5 px-1;
		}

		& pre {
			tab-size: 2;
			@apply overflow-x-auto rounded bg-transparent p-3 text-xs/relaxed;
		}

		& table {
			display: block;
			overflow-x: auto;
			text-wrap: balance;
			line-height: initial;

			@apply max-md:full-bleed min-w-full;

			& th,
			td {
				@apply p-3 text-center;
			}

			& tbody {
				& tr:nth-child(odd) {
					@apply bg-neutral-100;
				}

				& tr th:first-child {
					text-align: left;
				}
			}
		}
	}

	/* components */

	.accordion {
		& summary {
			display: grid;
			grid-template-columns: 1fr auto;
			align-items: center;
			gap: 1em;

			&::after {
				content: '+';
				display: inline-block;
				font-family: monospace;
				font-size: 1.5em;
				transition: rotate 0.2s;
			}
		}

		& ::-webkit-details-marker {
			display: none;
		}

		&[open] summary::after {
			rotate: 135deg;
		}
	}

	.carousel {
		display: flex;
		overflow-x: auto;
		scroll-snap-type: x mandatory;

		& > * {
			flex: 0 0 min(var(--size, 300px), 80vw);
			scroll-snap-align: center;
		}
	}
}

@layer utilities {
	.frosted-glass {
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	.frosted-glass-dark {
		-webkit-backdrop-filter: blur(16px);
		backdrop-filter: blur(16px);
		z-index: 40;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		background: rgba(0, 0, 0, 0.6);
	}

	.full-bleed {
		width: 100vw;
		margin-inline: calc(50% - 50vw);
	}

	.no-scrollbar {
		-webkit-overflow-scrolling: touch;
		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}

	.overflow-fade {
		--offset: 4rem;
		mask: linear-gradient(
			to right,
			transparent,
			black var(--offset),
			black calc(100% - var(--offset)),
			transparent
		);
	}

	.overflow-fade-r {
		--offset: 4rem;
		mask: linear-gradient(
			to right,
			black 50%,
			black calc(100% - var(--offset)),
			transparent
		);
	}

	.sticky-below-header {
		position: sticky;
		top: calc(var(--header-height) + var(--offset, 0px));
	}

	/* animations */

	@keyframes anim-fade-to {
		from {
			opacity: 0;
			translate: var(--x, 0) var(--y, 0);
		}
	}

	.anim-fade,
	.anim-fade-to-l,
	.anim-fade-to-r,
	.anim-fade-to-t,
	.anim-fade-to-b {
		--x: 0;
		--y: 0;
		animation: anim-fade-to 0.2s ease-in-out forwards;
	}

	.anim-fade-to-l {
		--x: 1rem;
	}

	.anim-fade-to-r {
		--x: -1rem;
	}

	.anim-fade-to-t {
		--y: 1rem;
	}

	.anim-fade-to-b {
		--y: -1rem;
	}
}

@layer base {
	* {
		@apply border-border;
	}
	body {
		@apply bg-background text-foreground;
	}
}

.blurred-overlay {
	backdrop-filter: blur(4px);
	-webkit-backdrop-filter: blur(4px);
	background-color: rgba(0, 0, 0, 0.1); /* Important for iOS to render blur */
}
